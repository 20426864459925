import React from 'react'
import './Header.css'
import Navbar from '../Navbar/Navbar'
import mobile from '../../Asist/img/mobile bottom 1.png'
export default function Header() {
    return (
        <header id='home'>
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <h1 className='top'>Денежные переводы из России в Узбекистан </h1>
                        <span>Лучший курс сума к рублю в приложении Unired.  <br/>Без комиссии </span>
                        <div className="box">
                            <a href="https://onelink.to/unired_mobile_app"><button className='play'><i class="fa-solid fa-play"></i>Google Play</button></a>
                            <a href="https://onelink.to/unired_mobile_app"><button className='store'><i class="fa-brands fa-apple"></i>App Store</button></a>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <img className='mobile' src={mobile} alt="" />
                    </div>
                </div>
            </div>
        </header>
    )
}
