import React, { useState } from 'react'
import './Navbar.css'
import logo from '../../Asist/img/logo.svg'
export default function Navbar() {
    const [show, setShow] = useState(false)
    return (
        <nav>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <img className='logo' src={logo} alt="" />
                        <i onClick={() => setShow(!show)} className="bars fa-solid fa-bars"></i>
                        
                        <div className={show ? 'show' : 'hide'}>
                            <div className="navbar">
                                <ul>
                                    <li>
                                        <a href="#home">
                                            Главная
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#one">
                                            Преимущество
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#two">
                                            Оплата
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#three">
                                            Возможности
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#five">
                                            Ответы на вопросы
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#six">
                                            Контакты
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}
