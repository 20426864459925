import React from 'react'
import './SectionSix.css'
// import foto1 from '../../Asist/img/mobile bottom 1.png'
// import foto2 from '../../Asist/img/mobile bottom 2.png'
// import foto3 from '../../Asist/img/mobile bottom 3.png'
// import foto4 from '../../Asist/img/mobile bottom 4.png'
// import foto5 from '../../Asist/img/mobile bottom 5.png'
import Accordion from 'react-bootstrap/Accordion';

export default function SectionSix() {
    return (
        <section id='five' className='six'>
            <div className="container">
                {/* <div className="row">
                    <div className="col-lg-12">
                        <h1>Скриншоты приложения</h1>
                        <h2>Интерфейс приложения</h2>
                    </div>
                </div>
                <div className="photo row">
                    <div className="col-auto">
                        <img src={foto1} alt="" />
                    </div>
                    <div className="col-auto">
                        <img src={foto2} alt="" />
                    </div>
                    <div className="col-auto">
                        <img src={foto3} alt="" />
                    </div>
                    <div className="col-auto">
                        <img src={foto4} alt="" />
                    </div>
                    <div className="col-auto">
                        <img src={foto5} alt="" />
                    </div>
                </div> */}
                <div className="bottom row">
                    <div className="col-lg-12">
                        <h1>Поддержка</h1>
                        <h2>Узнайте ответы на эти вопросы</h2>
                    </div>
                    <div className="col-lg-12">
                        <div className="box">
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>С какой банковской карты России можно переводить?</Accordion.Header>
                                    <Accordion.Body>
                                    Отправить средства можно с любой российской карты на карты Узбекистана.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Какая комиссия при переводе?</Accordion.Header>
                                    <Accordion.Body>
                                    Денежные переводы из России в Узбекистан и из Узбекистана в Россию осуществляются без комиссии.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Какой установлен лимит по переводам?</Accordion.Header>
                                    <Accordion.Body>
                                    Минимальная сумма для осуществления перевода — 1.000 рублей. Максимальный лимит на один денежный перевод — 100.000 рублей (возможен дополнительный платеж).
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Можно менять валюту?</Accordion.Header>
                                    <Accordion.Body>
                                    Валюта может быть изменена при оплате (рубль / сум).
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Какой лимит по количеству переводов в день? </Accordion.Header>
                                    <Accordion.Body>
                                    Дневной лимит составляет 145 000 000 сом на 1 карту.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
