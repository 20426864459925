import React from 'react'
import './SectionThree.css'
export default function SectionThree() {
    return (
        <section id='two' className='three'>
            <div className="container">
                <div className="row">
                    <h1>Оплатите находясь в России</h1>
                    <div className="col-lg-4">
                        <div className="title">
                            <div className="box">
                                <div className="img"><i class="home fa-solid fa-house-laptop"></i></div>
                                <p>Коммунальные <br />платежи</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="title">
                            <div className="box">
                                <div className="img">
                                    <i class="fa-solid fa-globe"></i>
                                </div>
                                <p>Интернет и мобильная <br /> связь</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="title">
                            <div className="box">
                                <div className="img">
                                    <i class="school fa-solid fa-school"></i>
                                </div>
                                <p>Государственные и <br/> частные школы</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="title">
                            <div className="box">
                                <div className="img">
                                    <i class="fa-regular fa-building"></i>
                                </div>
                                <p>Детские  <br/>сады</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="title">
                            <div className="box">
                                <div className="img">
                                    <i class="game fa-solid fa-gamepad"></i>
                                </div>
                                <p>Компьютерные <br/>игры</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="title">
                            <div className="box">
                                <div className="img">
                                    <i class="fa-solid fa-taxi"></i>
                                </div>
                                <p>Услуги<br/> такси</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
