import React from 'react'
import './SectionEight.css'
export default function SectionEight() {
    return (
        <section className='eight'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Загрузите приложение сейчас и испытайте все возможности</h1>
                        <div className="box">
                            <a href="https://onelink.to/unired_mobile_app"><button className='play'><i class="fa-solid fa-play"></i>Google Play</button></a>
                            <a href="https://onelink.to/unired_mobile_app"><button className='store'><i class="fa-brands fa-apple"></i>App Store</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
