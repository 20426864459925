import React from 'react'
import './SectionFive.css'
export default function SectionFive() {
    return (
        <section className='five'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                            <p>Посмотрите видеоролик</p>
                        <div className="box">
                            <iframe class="brz-iframe intrinsic-ignore" allowfullscreen="true" allow="autoplay" title="Rossia kartalaridan komissiyasiz pul yuborish" src="https://www.youtube.com/embed/XvX4vJTilsk?autoplay=0&controls=1&start=…end=0&modestbranding=0&wmode=transparent&enablejsapi=1&loop=0&rel=0&mute=0" id="widget6">…</iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
