import React from 'react'
import './SectionTwo.css'
export default function SectionTwo() {
    return (
        <section id='three' className='two'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>
                        Новые возможности обновленного <br/>мобильного приложения
                        </h1>
                    </div>
                    <div className="col-lg-3">
                        <p className="top">
                            <i class="fa-solid fa-check"></i>Все переводы - бесплатно
                        </p>
                        <p className="bottom">Все переводы с карт любого банка в России на карты в Узбекистане осуществляются без комиссии.Это удобное и выгодное решение.</p>
                    </div>
                    <div className="col-lg-3">
                        <p className="top">
                            <i class="fa-solid fa-check"></i>ЖИВИТЕ БЕЗ ГРАНИЦ
                        </p>
                        <p className="bottom">Оплачивайте с российских карт мобильную связь, коммунальные и государственные услуги компьютерные игры и многое предоставляемые в Узбекистане.</p>
                    </div>
                    <div className="col-lg-3">
                        <p className="top">
                            <i class="fa-solid fa-check"></i>БУДЬТЕ НА ОДНОЙ ВОЛНЕ
                        </p>
                        <p className="bottom">Совершайте денежные переводы и платежи между местными узбекскими картами Uzcard и Humo.</p>
                    </div>
                    <div className="col-lg-3">
                        <p className="top">
                            <i class="fa-solid fa-check"></i>КОНТРОЛИРУЙТЕ
                        </p>
                        <p className="bottom">Отслеживайте свои платежи, поступления и переводы в удобной системе мониторинга Unired.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
