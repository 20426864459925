import React from 'react'
import './Footer.css'
import logo from '../../Asist/img/logo.svg'
export default function Footer() {
    return (
        <footer>
            <div className="container">
                {/* <div className="row">
                    <div className="col-auto">
                        <img src={logo} alt="" />
                        <p className='last'>Удобные и легкие денежные <br />переводы из России в <br />Узбекистан.</p>
                        <div className="box">
                            <i className="fa-brands fa-youtube"></i>
                            <i className="fa-brands fa-telegram"></i>
                            <i className="fa-brands fa-instagram"></i>
                            <i className="fa-brands fa-tiktok"></i>
                        </div>
                    </div>
                    <div className="col-auto">
                        <p className="menu">Компания</p>
                        <ul>
                            <li>
                                <a href="#!">Контакты</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-auto">
                        <p className="menu">Приложение</p>
                        <ul>
                            <li>
                                <a href="#!">Скачать</a>
                            </li>
                            <li>
                                <a href="#!">Денежные переводы</a>
                            </li>
                            <li>
                                <a href="#!">Возможности</a>
                            </li>
                            <li>
                                <a href="#!">Вопросы</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-auto">
                        <p className="menu">Ссылки</p>
                        <ul>
                            <li>
                                <a href="#!">Политика<br />
                                    конфиденциальности</a>
                            </li>
                        </ul>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col-lg-12">
                        <img src={logo} alt="" />
                        <p className="bottom">© 2024г. UNIRED. Все права защищены.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}
