import React from 'react'
import './SectionOne.css'
export default function SectionOne() {
  return (
    <section id='one' className='one'>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className=''>Преимущество мобильного приложения Unired</h2>
          </div>
          <div className="col-lg-3">
            <div className="title">
              <div className="img">
              <i class="fa-solid fa-check"></i>
              </div>
              <p className="p-title">Без комиссии</p>
              <p className="bottom">Денежные переводы из России в Узбекистан абсолютно без комиссии</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="title">
              <div className="img">
              <i class="fa-solid fa-check"></i>
              </div>
              <p className="p-title">Обмен валюты</p>
              <p className="bottom">Лучший курс узбекского сума к российскому рублю в Узбекистане</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="title">
              <div className="img">
              <i class="fa-solid fa-check"></i>
              </div>
              <p className="p-title">Минимум</p>
              <p className="bottom">Минимальная сумма перевода составляет 1000 рублей</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="title">
              <div className="img">
              <i class="fa-solid fa-check"></i>
              </div>
              <p className="p-title">Максимум</p>
              <p className="bottom">Максимум 100 000 рублей за один перевод (есть возможность доплаты)</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
