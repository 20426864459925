import React from 'react'
import Header from './Companents/Header/Header'
import SectionOne from './Companents/Section-One/SectionOne'
import SectionTwo from './Companents/Section-Two/SectionTwo'
import SectionThree from './Companents/Section-Three/SectionThree'
import SectionFive from './Companents/Section-Five/SectionFive'
import SectionSix from './Companents/Section-Six/SectionSix'
import SectionSeven from './Companents/Section-Seven/SectionSeven'
import SectionEight from './Companents/Section-Eight/SectionEight'
import Footer from './Companents/Footer/Footer'

export default function App() {
  return (
    <div>
      <Header />
      <SectionOne />
      <SectionThree />
      <SectionTwo />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <SectionEight />
      <Footer />
    </div>
  )
}
